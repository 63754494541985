.App {
  text-align: center;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  background: linear-gradient( hsl(297, 88%, 38%), rgb(44, 185, 12));
  /* background: linear-gradient( #f906c8, rgb(44, 185, 12)); */

}

.top-box{

  min-width: 100%;
  min-height: 20vh;
  transform: skewY(-6deg);
  transform-origin: bottom right;
  border-top-left-radius: 50% 20%;
  border-bottom-left-radius: 30% 20%;
  border-bottom-right-radius: 50% 20%;

opacity: 0.7;

opacity: 1;
background-image:  linear-gradient(135deg, #000000 25%, transparent 25%), linear-gradient(225deg, #000000 25%, transparent 25%), linear-gradient(45deg, #000000 25%, transparent 25%), linear-gradient(315deg, #000000 25%, #e5e5f700 25%);
background-position:  16px 0, 16px 0, 0 0, 0 0;
background-size: 16px 16px;
background-repeat: repeat;
  text-align: start;
}

.mid-box{

  border: 10px dotted rgb(255, 255, 255);
  padding: 50px;
  min-width: 50%;
  min-height: 30vh;
  border-radius: 100%;

  background-color: #000000;
opacity: 1;
background: radial-gradient(circle, transparent 20%, #000000 20%, #000000 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #000000 20%, #000000 80%, transparent 80%, transparent) 17.5px 17.5px, linear-gradient(#ffffff 1.4000000000000001px, transparent 1.4000000000000001px) 0 -0.7000000000000001px, linear-gradient(90deg, #ffffff 1.4000000000000001px, #000000 1.4000000000000001px) -0.7000000000000001px 0;
background-size: 35px 35px, 35px 35px, 17.5px 17.5px, 17.5px 17.5px;

box-shadow: 0px 0px  50px rgb(251, 2, 222), 0px 0px  100px rgb(0, 0, 0);
}

.mid-inside-rectangle{
  background-color: rgb(0, 0, 0);
  border: 10px double rgb(212, 7, 168);
  min-width: 50%;
  min-height: 30vh;

}

.image-container{
  min-height: 30vh; 
}

.image-caption{
  color: #f7e9e5;
  background-color: #000000;
  width: auto;
}

.explanation{
  color: #f7e9e5;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 0px;
}

h1{
  color: rgb(255, 255, 255);
  margin-left: 50px;
  font-family: 'Source Serif Pro', serif;
  text-shadow:
  0 0 7px rgb(0, 0, 0),
  0 0 10px rgb(0, 0, 0),
  0 0 21px rgb(0, 0, 0),
  0 0 42px rgb(255, 0, 212),
  0 0 82px rgb(255, 0, 212),
  0 0 92px rgb(255, 0, 212),
  0 0 102px rgb(255, 0, 212),
  0 0 151px rgb(255, 0, 212);
  margin-bottom: 10px;
  padding-bottom: 0px;

}


.bottom-box{

  min-width: 100vw;
  min-height: 12vh;
  display: flex;
  flex-direction: row;
  justify-content:space-between ;
  background-color: #000000;
  opacity: 1;
  background: repeating-linear-gradient( 45deg, #ffffff, #ffffff 9px, #000000 9px, #000000 45px );
}

.arrow-box{
  background-color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  font-size: xx-large;
}

.center-message{
  
  font-size: medium;
  height: auto;
  max-width: 40vw;
  min-height: 50px;
}

.center-message > p{
  background-color: #ffffff;
  padding: 4px;
  min-height: 45px;
}

.video-box{
  background-color: #f7e9e5;
  min-width: 100%;
  height: 150px;
  transform: skewY(6deg);
  transform-origin: bottom right;
  padding: 0;
  position: absolute;
}

.video-container{
  height: 100%;
  overflow:hidden;
  display:block;
}

#video{
  width: 100%;
  
  /* margin-top: -100px; */
  padding: 0;
}

.highlight-text{
  color: #f906c8;
  font-size: larger;
  font-weight: bold;
}
